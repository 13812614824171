import { createTheme } from "@mui/material/styles";
import { crimsonRed, forestGreen, primary } from "../../assets/colors";
// --------------------------------------------------------------------------------
// NOTE Create Custom Theme
export const theme = createTheme({
  // direction: "rtl",
  palette: {
    // mode: "dark",
    // background: {
    //   paper: backgroundColor,
    //   default: backgroundColor,
    // },
    // background: {
    //   default: "#FFFFFF",
    // },
    primary: {
      main: primary,
      contrastText: "#FFFFFF",
    },
    error: {
      main: crimsonRed,
    },
    // secondary: {
    //   main: "#ffffff",
    // },
    // tertiary: {
    //   main: sereneSkyBlueColor,
    //   contrastText: primaryColor,
    // },

    // success: {
    //   main: successColor,
    // },
    // warning: {
    //   main: warningColor,
    // },
    // black: {
    //   main: blackColor,
    // },
    // silverSage: {
    //   main: silverSageColor,
    //   contrastText: forestShadowColor,
    // },
    // emeraldOasis: {
    //   main: mintMistColor,
    //   contrastText: emeraldOasisColor,
    // },
    // slateGray: {
    //   main: slateGrayColor,
    //   // contrastText: emeraldOasisColor,
    // },
    // arcticMist: {
    //   main: arcticMistColor,
    //   contrastText: slateGrayColor,
    // },
    // frostyVeil: {
    //   main: frostyVeilColor,
    //   contrastText: whiteColor,
    // },
    // arcticMist2: {
    //   main: arcticMistColor,
    //   contrastText: forestShadowColor,
    // },
    // arcticMist3: {
    //   main: arcticMistColor,
    //   contrastText: primaryColor,
    // },
    // arcticMist4: {
    //   main: arcticMistColor,
    //   contrastText: darkNavyBlueColor,
    // },
    // fireEngineRed: {
    //   main: fireEngineRedColor,
    //   contrastText: whiteColor,
    // },
    // aliceBlue: {
    //   main: aliceBlueColor,
    //   contrastText: fireEngineRedColor,
    // },
    // black: {
    //   main: blackColor,
    // },
    // brightTurquoise: {
    //   main: brightTurquoiseColor,
    //   contrastText: primaryColor,
    // },
    // charcoalGray: {
    //   main: charcoalGrayColor,
    //   contrastText: whiteColor,
    // },
    // goldenCream: {
    //   main: goldenCreamColor,
    //   contrastText: amberBlazeColor,
    // },
    // rosyGlow: {
    //   main: rosyGlowColor,
    //   contrastText: crimsonBlazeColor,
    // },
    // paleBlue: {
    //   main: paleBlueColor,
    //   contrastText: forestShadowColor,
    // },
    // peachPink: {
    //   main: peachPinkColor,
    //   contrastText: rustRedColor,
    // },
  },
  typography: {
    fontFamily: ["Dm sans", "IRANSansXVF"].join(","),
  },
  components: {
    //   MuiOutlinedInput: {
    //     styleOverrides: {
    //       input: {
    //         fontSize: 14,
    //         paddingTop: 12.5,
    //         paddingBottom: 12.5,
    //       },
    //       adornedEnd: {
    //         paddingRight: 6,
    //       },
    //     },
    //   },
    //   MuiSelect: {
    //     styleOverrides: {
    //       outlined: {
    //         height: 0,
    //       },
    //     },
    //   },
    //   MuiAutocomplete: {
    //     styleOverrides: {
    //       input: {
    //         height: ".867em",
    //       },
    //     },
    //   },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          borderRadius: 7,
        },
        // contained: {
        //   paddingTop: ".6em",
        //   paddingBottom: ".6em",
        //   // color: "#ffffff",
        // },
        // text: {
        //   ":hover": {
        //     backgroundColor: "transparent",
        //   },
        // },
        // sizeSmall: {
        //   fontSize: 14,
        // },
      },
    },
    //   MuiCard: {
    //     styleOverrides: {
    //       root: {
    //         backgroundColor: arcticMistColor,
    //         borderRadius: 15,
    //       },
    //     },
    //     defaultProps: {
    //       elevation: 0,
    //     },
    //   },
    //   MuiTab: {
    //     styleOverrides: {
    //       root: {
    //         textTransform: "none",
    //       },
    //     },
    //   },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: forestGreen,
        },
      },
      // defaultProps: { color: graphiteGrayColor },
    },
    //   MuiDialogContentText: {
    //     styleOverrides: {
    //       root: {
    //         color: graphiteGrayColor,
    //       },
    //     },
    //   },
    //   // MuiPaper: {
    //   //   styleOverrides: {
    //   //     root: {
    //   //       background: "none",
    //   //     },
    //   //   },
    //   // },
  },
});
